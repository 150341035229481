import request from '@/utils/request.js'

export function listPage(params) {
    return request({
        method:'get',
        url:'/api/shop/amazonListPage',
        params
    });
}

export function addStoreInit(params) {
    return request({
        method:'get',
        url:'/api/shop/addShopAndAuthorizeInit',
    });
}

// 授权
export function toAccredit(data){
    return request({
        method:'post',
        url:'/api/shop/addShopAndAuthorize',
        data
    });
}

// 更新初始化
export function updateInit(id) {
    return request({
        method:'get',
        url:`/api/shop/amazonUpdateInit/${id}`
    });
}

// 更新授权
export function updateAuthorize(data) {
    return request({
        method:'post',
        url:'/api/shop/authorizeNow',
        data
    });
}

// 查询初始化
export function searchInit() {
    return request({
        method:'get',
        url:'/api/shop/pageInit',
    });
}

// 获取站点信息
export function getMarketPlace(id) {
    return request({
        url:`/api/shop/getMarketPlace/${id}`,
        method:'get'
    });
}
// 默认展示
export function defaultShow(id) {
    return request({
        url:`/api/shopMarketPlace/defaultShow/${id}`,
        method:'post'
    });
}
export function changeStatus(id) {
    return request({
        url:`/api/shopMarketPlace/enable/${id}`,
        method:'post'
    });
}

export function getAuthorizeMsg(params) {
    return request({
        url:'/api/shop/getAuthorizeMsg',
        method:'get',
        params
    })
}

//广告授权
export function advertAuthorize(params) {
    return request({
        url:'/api/shop/advertAuthorize',
        method:'get',
        params
    });
}

// 获取广告URL
export function getSloganURL(id) {
    return request({
        method:'post',
        url:`/api/shop/getAdvertAuthorizeUrl/${id}`
    });
}

export function exitShopName(data) {
    return request({
        method:'put',
        url:'/api/shop',
        data
    });
}

// 邮件List
export function mailList(params) {
    return request({
        url:'/api/mailAccount/list',
        method:'get',
        params
    });
}

// 邮箱查询初始化
export function mailPageInit(shopId) {
    return request({
        url:'/api/mailAccount/pageInit',
        method:'get',
        params:{
            shopId:shopId
        }
    });
}

// 新增邮箱
export function mailAccount(data) {
    return request({
        url:'/api/mailAccount',
        method:'post',
        data
    });
}

//编辑邮箱初始化
export function editMailInit(id) {
    return request({
        url:`/api/mailAccount/updateInit/${id}`,
        method:'get',
    });
}

//编辑邮箱秘钥
export function mailPassword(data) {
    return request({
        url:'/api/mailAccount',
        method:'put',
        data
    });
}


export function removeMail(id,shopId,marketPlaceId) {
    return request({
        url:`/api/mailAccount/delete?id=${id}&shopId=${shopId}&marketPlaceId=${marketPlaceId}`,
        method:'delete',
    });
}

export function ShopRakutenUpdate(data) {
    return request({
        url:'/api/MailRakuten/ShopRakutenUpdate',
        method:'post',
        data
    });
}

export function shopify(params) {
    return request({
        url:'/api/shop/authorization/shopify',
        method:'get',
        params
    });
}

export function shopRakutenAdd(data) {
    return request({
        url:'/api/MailRakuten/shopRakutenAdd',
        method:'post',
        data
    });
}